// @ts-nocheck
import { datadogLogs } from "@datadog/browser-logs";
import { parseOneAddress } from "email-addresses";
import { parse } from "ipaddr.js";
import { isPossiblePhoneNumber } from "libphonenumber-js";
import { SnackbarMessageOptions } from "notistack";

export const GetSnackBarOptions = (optionsParams): SnackbarMessageOptions => ({
  message: optionsParams.messageElement,
  key: optionsParams.actionKey,
  options: {
    variant: optionsParams.variantType,
    autoHideDuration: 4000,
    preventDuplicate: true,
    disableWindowBlurListener: true,
    anchorOrigin: {
      vertical: "top",
      horizontal: "center",
    },
  },
});

export const isValidIpAddress = (ipAddress) => {
  const ipRegex =
    /(?:^(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}$)|(?:^(?:(?:[a-fA-F\d]{1,4}:){7}(?:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){6}(?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|:[a-fA-F\d]{1,4}|:)|(?:[a-fA-F\d]{1,4}:){5}(?::(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,2}|:)|(?:[a-fA-F\d]{1,4}:){4}(?:(?::[a-fA-F\d]{1,4})?:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,3}|:)|(?:[a-fA-F\d]{1,4}:){3}(?:(?::[a-fA-F\d]{1,4}){0,2}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,4}|:)|(?:[a-fA-F\d]{1,4}:){2}(?:(?::[a-fA-F\d]{1,4}){0,3}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,5}|:)|(?:[a-fA-F\d]{1,4}:)(?:(?::[a-fA-F\d]{1,4}){0,4}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,6}|:)|(?::(?:(?::[a-fA-F\d]{1,4}){0,5}:(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)(?:\\.(?:25[0-5]|2[0-4]\d|1\d\d|[1-9]\d|\d)){3}|(?::[a-fA-F\d]{1,4}){1,7}|:)))(?:%[0-9a-zA-Z]+)?$)/;

  if (!ipRegex.test(ipAddress)) {
    return false;
  }

  try {
    const addressRange = parse(ipAddress).range();
    const acceptedRanges = ["unicast"];
    return acceptedRanges.includes(addressRange);
  } catch (e) {
    return false;
  }
};

export const isValidPhone = (phone) => {
  try {
    return isPossiblePhoneNumber(phone, "US");
  } catch (e) {
    return false;
  }
};

export const isValidEmail = (email) => {
  if (!email) {
    return false;
  }

  const emailObj = parseOneAddress(email.toLowerCase());

  const validDomain =
    /^\[?((25[0-5]|(2[0-4]|1\d|[1-9]|)\d)(\.(?!$)|$)){4}$\]?/.test(
      emailObj?.domain
    ) || /^([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,}$/.test(emailObj?.domain);

  const invalidChars = /[(){}[\]]/;

  return !!(
    emailObj?.local &&
    emailObj?.domain &&
    validDomain &&
    !invalidChars.test(email)
  );
};

export const clientInfo = {
  env: process.env.REACT_APP_ENV,
  version: process.env.REACT_APP_VERSION,
};

export const downloadFile = (src, title, fileType = "text/plain") => {
  const element = document.createElement("a");
  const file = new Blob([src], { type: fileType });
  element.href = URL.createObjectURL(file);
  element.download = title;
  document.body.appendChild(element); // Required for this to work in FireFox
  element.click();
};

export const appErrorHandler = (error) => {
  datadogLogs.logger.error(error.message, { ...clientInfo, key: "app_error" });
};

export const getDefaultApp = (policy) => {
  const app = policy.applications.filter((a) => a.name === "Default")?.pop();
  if (!app) {
    return policy.applications[0];
  }
  return app;
};

export const renameDupe = (names, newName, i = 1) => {
  const re = /\(\d*?\)/g;
  let renamed = newName;
  if (names?.includes(renamed)) {
    renamed += ` (${i})`;
    if (names?.includes(renamed)) {
      const matches = renamed.match(re);
      const lastMatch = matches[matches.length - 1];
      const lastMatchInt = Number(lastMatch.replace("(", "").replace(")", ""));
      renamed = renameDupe(names, newName, lastMatchInt + 1);
    }
  }
  return renamed;
};

export function isInvalidHttpsUrl(string: string) {
  if (!string) return false;
  let url;
  try {
    url = new URL(string);
  } catch {
    return true;
  }
  return url.protocol !== "https:";
}

export function isInvalidDomain(string: string): boolean {
  const domainRegex =
    /^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,}$/;

  if (domainRegex.test(string)) {
    return false; // Valid domain
  }

  try {
    new URL(string);
    return false; // Valid URL
  } catch {
    return true; // Not a valid URL or domain
  }
}
